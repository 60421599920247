<template>
  <button :class="buttonClasses"
          :disabled="disabled"
          class="flex justify-center items-center space-x-2"
          v-bind="$attrs"
          @click="handleClick">
    <template v-if="$attrs.hasOwnProperty('reverse-content')">
      <slot></slot>

      <span v-if="text">
        {{ text }}
      </span>
    </template>

    <template v-else>
      <span v-if="text">
        {{ text }}
      </span>

      <slot></slot>
    </template>
  </button>
</template>

<script lang="ts" setup>

interface Props {
  variant: 'white' | 'primary' | 'secondary' | 'warning' | 'danger' | 'info' | 'success'
  size: 'xs' | 'sm' | 'md' | 'lg'
  disabled?: boolean
  outline?: boolean
  text?: string
}

const props = withDefaults(defineProps<Props>(), {
  size: 'md',
  disabled: false,
  outline: false,
})

const buttonClasses = computed(() => {
  const prefix = props.outline ? 'btn-outline-' : 'btn-'
  const variantSizeClass = `${prefix}${props.variant}-${props.size}`
  const disabledClass = props.disabled ? 'btn-disabled' : ''

  return [variantSizeClass, disabledClass].filter(Boolean)
})

const handleClick = (event: MouseEvent) => {
  if (!props.disabled) {
    emit('click', event)
  }
}

const emit = defineEmits(['click'])
</script>